@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  background: var(--color-background) ;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
:root {
    /* COLORS */
    --color-text: #7C7C7C;
    --color-diviser: #E8E8E8;
    --color-background: #f5f6fa;

    /* FONTS */
    /* DO NOT TOUCH. This element is the global size reference */
    font-size: 1em;
    font-size: var(--reference-font-size-1);
    --reference-font-size-1: 1em; /* DO NOT TOUCH. This element is the global size reference */

    /* Here you can touch */
    --font-size-1: 0.1rem;
    --font-size-2: 0.3rem;
    --font-size-3: 0.5rem;
    --font-size-4: 0.8rem;
    --font-size-5: 1rem;
    --font-size-6: 1.1rem;
    --font-size-7: 1.3rem;
    --font-size-8: 1.5rem;
    --font-size-9: 1.8rem;
    --font-size-10: 2rem;

}
.GlobalSubtitle {
    text-align: left;
    font-size: 1.5rem;
    font-size: var(--font-size-8);
    font-weight: 800;
}

.GlobalSubtitle_medium {
}

.GlobalSubtitle_small {
}

.GlobalText {
    color: #7C7C7C;
    color: var(--color-text);
}

.GlobalText_medium {
}

.GlobalText_small {
}

.GlobalDiviser {
    border: .1px solid #E8E8E8;
    border: .1px solid var(--color-diviser);
}
