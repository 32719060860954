:root {
    /* COLORS */
    --color-text: #7C7C7C;
    --color-diviser: #E8E8E8;
    --color-background: #f5f6fa;

    /* FONTS */
    /* DO NOT TOUCH. This element is the global size reference */
    font-size: var(--reference-font-size-1);
    --reference-font-size-1: 1em; /* DO NOT TOUCH. This element is the global size reference */

    /* Here you can touch */
    --font-size-1: 0.1rem;
    --font-size-2: 0.3rem;
    --font-size-3: 0.5rem;
    --font-size-4: 0.8rem;
    --font-size-5: 1rem;
    --font-size-6: 1.1rem;
    --font-size-7: 1.3rem;
    --font-size-8: 1.5rem;
    --font-size-9: 1.8rem;
    --font-size-10: 2rem;

}
.GlobalSubtitle {
    text-align: left;
    font-size: var(--font-size-8);
    font-weight: 800;
}

.GlobalSubtitle_medium {
}

.GlobalSubtitle_small {
}

.GlobalText {
    color: var(--color-text);
}

.GlobalText_medium {
}

.GlobalText_small {
}

.GlobalDiviser {
    border: .1px solid var(--color-diviser);
}